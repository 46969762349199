/* eslint-disable no-unused-vars */
/* ICONS */
import { MdEmail , MdOutlineInventory2 } from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";
import { BsEmojiSmile } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { TbCategoryPlus } from "react-icons/tb";
import { LuTags } from "react-icons/lu";
import { FaInstagram } from "react-icons/fa";
import { RiCoupon2Line } from "react-icons/ri";
import { IoHeadsetOutline } from "react-icons/io5";
import { FaRegMessage } from "react-icons/fa6";
const navMenu = [
  {
    id: 1,
    link: "/contact-us",
    icon: IoHeadsetOutline,
    name: "Contact Us",
  },
  {
    id: 2,
    link: "/career",
    icon: FaRegMessage,
    name: "Career",
  },
  {
    id: 3,
    link: "/news-letter",
    icon: MdEmail,
    name: "NewsLetter",
  },
];

export default navMenu;
