/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* STYLES */
import "../styles/global.css";

/* LAYOUT */
import Layout from "../layout";

/* PAGES */
import AuthLogin from "../pages/auth/login";
import AuthRegister from "../pages/auth/register";
import Career from "../pages/dashboard/career";
import ContactUs from "../pages/dashboard/contact-us";
import NewsLetter from "../pages/dashboard/news-letter";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<AuthLogin />} />
          <Route path="/register" element={<AuthRegister />} />
          <Route
            path="/career"
            element={
              <Layout>
                <Career />
              </Layout>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />
          <Route
            path="/news-letter"
            element={
              <Layout>
                <NewsLetter />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
