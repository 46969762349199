/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import moment from "moment";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

/* ICONS */
import { FiTrash } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import { deleteCareerDetailsById, getCareerList } from "../../../service/api";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../../config";

const Career = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [careerList, setCareerList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getCareerListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getCareerListFunction();
  };

  const getCareerListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };
    if (search) {
      params.search = search;
    }

    getCareerList(params)
      .then((res) => {
        setCareerList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteCareerDetailsById(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getCareerListFunction();
      });
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      width: "10vh",
      selector: (row, index) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      grow: 1,
      selector: (row) => (
        <div className="flex items-center gap-2">
          <p className="text-sm font-proxima-semibold text-black text-opacity-60">
            {row.name}
          </p>
        </div>
      ),
    },
    {
      name: "Email",
      grow: 2,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.email}
        </span>
      ),
    },
    {
      name: "Phone",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.phoneNumber}
        </span>
      ),
    },
    {
      name: "Experience",
      grow: 2,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.experience}
        </span>
      ),
    },
    {
      name: "Type",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.type}
        </span>
      ),
    },

    {
      name: "Timestamp",
      grow: 2,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {new Date(row.createdAt).toLocaleDateString("en-gb", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour:"2-digit",
            minute:"2-digit",
            second: "2-digit",
            hour12: true
          })}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Button
            onClick={() => {
              setSelectedContact(row);
              setViewModal(true);
            }}
            className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
          >
            <MdOutlineRemoveRedEye className="w-4 h-4 text-greyText" />
          </Button>
          <Button
            onClick={() => {
              setDeleteId(row?._id);
              setDeleteModal(!deleteModal);
            }}
            className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
          >
            <FiTrash className="w-4 h-4 text-greyText" />
          </Button>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getCareerListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Career Queries | INC Design Agency</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Career
            </h1>
            <h2 className="text-lg font-proxima-regular text-greyText tracking-tight">
              Manage incoming inquiries from website users.
            </h2>
          </div>
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-3/4 xl:w-full lg:w-full mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? careerList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>

        {/* DELETE MODAL */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Delete Dategory
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-proxima-regular text-black">
                Are you sure you want to delete this details?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* VIEW MODAL */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            {/* Dialog Header */}
            <div className="border-b border-gray-200 px-5 py-3 flex items-center justify-between bg-white">
              <h5 className="text-lg font-proxima-semibold text-gray-800 tracking-tight">
                View Contact Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>

            {/* Dialog Body */}
            <div className="border-b border-gray-200 bg-white">
              {selectedContact && (
                <div className="p-5">
                  <p className="text-base font-proxima-semibold mb-3">
                    Name:{" "}
                    <span className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.name}
                    </span>
                  </p>

                  <p className="text-base font-proxima-semibold mb-3">
                    Email:{" "}
                    <span className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.email}
                    </span>
                  </p>

                  <p className="text-base font-proxima-semibold mb-3">
                    Phone Number:{" "}
                    <span className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.phoneNumber}
                    </span>
                  </p>

                  <p className="text-base font-proxima-semibold mb-3">
                    Experience:{" "}
                    <span className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.experience}
                    </span>
                  </p>

                  <p className="text-base font-proxima-semibold mb-3">
                    Current LPA:{" "}
                    <span className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.currentLPA}
                    </span>
                  </p>

                  <p className="text-base font-proxima-semibold mb-3">
                    Expected LPA:{" "}
                    <span className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.expectedLPA}
                    </span>
                  </p>

                  <p className="text-base font-proxima-semibold mb-3">
                    Description:{" "}
                    <span className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.description}
                    </span>
                  </p>
                  <p className="text-base font-proxima-semibold mb-3">
                    Commuting To Location:{" "}
                    <span className="text-base font-proxima-regular text-black">
                      {" "}
                      {selectedContact.commutingToLocation ? "Yes" : "No"}
                    </span>
                  </p>

                  {selectedContact?.attachedDocument?.length > 0 &&
                    selectedContact?.attachedDocument?.map((element, index) => {
                      return (
                        <p className="text-base font-proxima-semibold mb-3">
                          {element?.documentName}:{" "}
                        <Link to={IMAGE_URL+ element?.documentURL} target="_blank">
                        <span  className="text-base font-proxima-bold text-black">
                            {" "}
                            View Document
                          </span>
                        </Link>  
                        </p>
                      );
                    })}

                  <p className="text-base font-proxima-semibold mb-3">
                    Timestamp:{" "}
                    <span className="text-base font-proxima-regular text-black">
                      {" "}
                      {moment(selectedContact.createdAt).format(
                        "MMM DD, YYYY HH:MM A"
                      )}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default Career;
