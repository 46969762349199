const SERVER_URL =
  "https://inc-design-website-backend-nodejs-2mk6mheziq-el.a.run.app/admin/";
const IMAGE_URL = "https://storage.googleapis.com/kidsstreet-test/";
const SET_FORMAT = (amount) => {
  var formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  return formatter.format(amount);
};
module.exports = {
  SERVER_URL,
  IMAGE_URL,
  SET_FORMAT,
};
